import { Link } from "react-router-dom";
import { topBannerContent } from "./TopBannerContent";
import { TopBannerIcon } from "./TopBannerIcon";

const TopBanner = () => {
  if (!topBannerContent?.link) {
    return null;
  }
  return (
    <Link to={topBannerContent.link} target="blank">
      <div className="align-center text-center p-2 bg-[#FFEC41]">
        <span className="inline-flex align-center space-x-2">
          <span className="leading-4">{topBannerContent?.text}</span>{" "}
          <span className="text-xl leading-4">
            <TopBannerIcon />
          </span>
        </span>
      </div>
    </Link>
  );
};

export default TopBanner;
