import React, { useEffect, useMemo, useState } from "react"; import {
  StyledPopup,
  StyledModalHeader,
  StyledModalContent,
  StyledError,
} from "./RequestPopupStyle";
import CloseModal from "../../assets/img/close-modal.png";
import { apiUrl } from "../../utils"
import { useLinks } from "../../hooks"
import { EmailPopup, RegisterPopup } from "..";
import { yandexMetricaClientID } from "../../index"
import LoaderSpinner from "../LoaderSpinner";

import axios from "axios";
import styled from "styled-components";
import {handlePhoneChange} from "../../utils/common";

// const OverlayLoader = styled.div`
//   z-index: ${({ isVisible }) => isVisible ? '10000' : '-10000'};
//   opacity: ${({ isVisible }) => isVisible ? 0.8 : 0};
//   transition: all ease 0.15s;
//   display: flex;
//   flex-flow: row nowrap;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
//   width: 100%;
//   position: absolute;
//   background: #fff;
// `

const ButtonSubmit = styled.button.attrs({
  type: 'submit'
})`
  padding: ${({ isPending }) => isPending ? "0.4rem" : "1.1rem"};
  width: 100%;
  background: #00745F;
  border-radius: 100px;
  margin-top: 40px;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: #00745F;
  }

  &:active {
    background: #166839;
  }

  &:disabled {
    pointer-events: none;
    cursor: auto;
    background: #ccc;
  }
`

const ButtonInnerText = styled.p`
  display: ${({ isVisible }) => isVisible ? 'block' : 'none'};
  font-style: normal;
  font-family: "Sofia Pro"!important;
  font-weight: 500!important;
  font-size: 1.2rem!important;
  line-height: 1.4rem!important;
  color: #ffffff!important;
`

// eslint-disable-next-line no-unused-vars
export const RequestPopup = ({ title, closeModal, open, type, tag, tag2 }) => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("+7");
  const [status, setStatus] = useState();
  const [isPending, setIsPending] = useState(false);
  const [isRegistered, setIsRegistered] = useState(true)
  const [isNewUser, setIsNewUser] = useState()
  const [error, setError] = useState({ privacy: false, request: false });
  const [phoneError, setPhoneError] = useState(false);

  const { licenceLink } = useLinks()

  const reInit = () => {
    setOpenSuccess(false)
    setName("")
    setEmail("")
    setNumber("+7")
    setStatus()
    setIsPending(false)
    setIsRegistered(true)
    setIsNewUser(false)
    setError({ privacy: false, request: false})
    setPhoneError(false)
  }

  const computedRequestStatus = useMemo(() => {
    if (!status && status !== undefined) return <StyledError>Не удалось отправить данные</StyledError>
    if (error.request) return <StyledError>Ошибка при запросе к серверу. Пожалуйста, повторите запрос</StyledError>

    return;
  }, [status, isPending, error.request])

  const handleSubmit = (e) => {
    const isPrivacyAccept = e.target[3].checked;
    const privacyName = e.target[3].name;
    // TODO? Переменные для подстановки в строку запроса
    // const utmSource = "yandex"
    // const utmMedium = "cpc"
    // const utmCampaing = "detskaya"
    const googleClientID = "G-GSREK1D7HK"

    e.preventDefault();
    if (phoneError) {
      return
    }
    if (tag) {
      tag();
    }

    if (tag2) {
      tag2();
    }

    if (isPrivacyAccept) {
      setError((prev) => {
        return {
          ...prev,
          [privacyName]: false,
        };
      });

      setIsPending(true);
      setStatus(1);
      // TODO!: Также, можно типовые запросы (авторизуйся, заведи нового пользователя, ...) обернуть в хуки, чтобы ими было удобнее
      // TODO!: пользоваться
      axios.get(`${apiUrl}/auth/check-email?email=${email}`, {
        data: {
          "google_client_id": googleClientID,
          "yandex_client_id": String(yandexMetricaClientID)
        }
      }).then(({ data }) => {
        if (data) {
          setError((previous) => ({ ...previous, request: false}))

          axios.post(
            `${apiUrl}/saveform?name=${name}&email=${email}&phone_number=${number}&type=${
              type ? type : "demo_access"
            }`).then(({ data }) => {
            setStatus(data.status);

            data.status && setOpenSuccess(true);

            setIsPending(false);
          }).then(() => {
            if (data.status.trim() === "not_found") {
              setIsRegistered(false)

              setIsPending(false)
            }
          });
        } else {
          setError((previous) => ({ ...previous, request: true }))
        }
      })
    } else {
      setError((prev) => {
        return {
          ...prev,
          [privacyName]: true,
        };
      });
    }
  };

  const onRegisterClose = () => {
    setIsRegistered(true)
  }

  const onRegisterSuccess = () => {
    setIsRegistered(true)
    setIsNewUser(true)
  }

  const onConfirmRegisterSubmit = () => {
    setIsNewUser(false);
    closeModal();
    reInit()
  }

  useEffect(() => {
    return () => {
      reInit()
    }
  }, [])

  if (open && isNewUser) {
    return (
      <EmailPopup
        open
        title="Ура! А теперь последний шаг"
        bodyText="Для завершения регистрации, пожалуйста, перейдите по ссылке, которую мы отправили Вам на e-mail"
        submitText="Хорошо"
        closeModal={onConfirmRegisterSubmit}
      />
    );
  }

  if (open && !isRegistered) {
    return (
      <RegisterPopup
        isOpened
        onCloseModal={onRegisterClose}
        onSuccess={onRegisterSuccess}
        username={name}
        email={email}
      />
    );
  }

  if (open && status !== undefined && status !== 1 && openSuccess) {
    return (
      <EmailPopup
        title={"Запрос отправлен"}
        closeModal={() => {
          setOpenSuccess(false)
          closeModal()
        }}
        open={openSuccess}
      />
    )
  }

  return (
    <StyledPopup
      modal
      lockScroll
      closeOnDocumentClick
      openSuccess={openSuccess}
      open={open}
      onClose={() => {
        setOpenSuccess(false);
        closeModal();
      }}
    >
      <LoaderSpinner isWithOverlay isVisible={isPending} style={{ height: '8rem', width: '8rem' }} spinnerColor="#00745F" spinnerBorderWidth="1rem" />
      <StyledModalHeader>
        <p>{title}</p>
        <img
          onClick={() => {
            setOpenSuccess(false);
            closeModal();
          }}
          src={CloseModal}
          alt="no img"
        ></img>
      </StyledModalHeader>
      <StyledModalContent>
        <form onSubmit={(e) => handleSubmit(e)}>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Представьтесь"
            type="text"
          />
          <div style={{width: '100%'}}>
            <input
              value={number}
              onChange={(e) => handlePhoneChange(e, (e) => {
                setPhoneError(false)
                setNumber(e.target.value)
              })}
              required
              placeholder="Телефон"
              type="phone"
              onBlur={() => {
                if (number.length !== 18) {
                  setPhoneError(true)
                }
              }}
              style={phoneError ? {border:  '2px solid red', marginBottom: 0}: {}}
            />
            {phoneError && <p style={{color: 'red', marginBottom: 16}}>Телефон заполнен не до конца.</p>}
          </div>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email"
            type="email"
          />
          <label>
            <input name="privacy" type="checkbox" />
            Принимаю
            <a href={licenceLink} style={{ color: '#00745F'}}>
              политику конфиденциальности
            </a>
          </label>
          {error.privacy && <p>Пожалуйста, подтвердите согласие</p>}
          <ButtonSubmit disabled={isPending}>
            <ButtonInnerText isVisible={!isPending}>Отправить</ButtonInnerText>
          </ButtonSubmit>
          {computedRequestStatus}
        </form>
      </StyledModalContent>
    </StyledPopup>
  );
};
